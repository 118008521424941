import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { User } from '@app/shared/models/user.model';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { UserService } from '@app/users/user.service';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import userflow from 'userflow.js'
import { Timestamp } from 'rxjs-compat';
@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  private readonly CLINICS = 'CLINICS';
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly LAST_LOGIN = 'LAST_LOGIN';

  private _isLoggedIn: boolean = false;
  private currentUserSubject$: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(private http: HttpClient, private router: Router, private userService: UserService, private _ngxZendeskWebwidgetService: NgxZendeskWebwidgetService) {
    this.currentUserSubject$ = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject$.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject$.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.userApiUrl}/api/auth/signinfor2fa`, { "Username": username, "Password": password })
      .pipe(map(response => {

        if (response.authenticationResult && response.authenticationResult.idToken) {
          let user = {} as User;

          var decoded = jwtDecode(response.authenticationResult.idToken);

          var role = decoded['role'];
          if (role == 'Patient' || role == 'External') {
            return null;
          }

          user.displayName = decoded['displayName'];
          user.role = role;
          this.storeJwtToken(response.idToken);
          this.storeRefreshToken(response.refreshToken);
          this.storeJwtToken(response.authenticationResult.idToken);
          this.storeRefreshToken(response.authenticationResult.refreshToken);

          //  console.log(decoded);
          this.userService.storeClinics(decoded['clinics']);


          this.logLastLogin();

          localStorage.setItem('currentUser', JSON.stringify(user));

          if (decoded['email'])
            localStorage.setItem('email', decoded['email']);

          this.userService.setCurrentUser(user);
          this._ngxZendeskWebwidgetService.zE('webWidget', 'identify', {
            name: decoded['displayName'],
            email: decoded['email'],
          });
          this._ngxZendeskWebwidgetService.zE('webWidget', 'show');
          this.currentUserSubject$.next(user);

          userflow.init(environment.userFlowToken);
          userflow.identify(decoded['userid'], {
            name: decoded['displayName'],
            email: decoded['email'],
            // signed_up_at: '2019-06-14T16:25:49Z'
            //date.toUTCString()
          });

          return user;
        }
        else {
          return response;
        }
      },
        err => {
          console.error(err)
        }));
  }
  mfa(username: string, smsMfaCode: string, session: string) {
    return this.http.post<any>(`${environment.userApiUrl}/api/auth/mfa`, { "Username": username, "SmsMfaCode": smsMfaCode, "session": session })
      .pipe(map(response => {

        if (response.authenticationResult && response.authenticationResult.idToken) {
          let user = {} as User;


          var decoded = jwtDecode(response.authenticationResult.idToken);

          var role = decoded["role"]
          if (role == "Patient") {
            return null;
          }
          //  console.log(decoded);
          this.userService.storeClinics(decoded["clinics"]);

          user.displayName = decoded["displayName"]
          user.role = role;
          this.storeJwtToken(response.authenticationResult.idToken);
          this.storeRefreshToken(response.authenticationResult.refreshToken);
          this.logLastLogin();
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.userService.setCurrentUser(user);
          this._ngxZendeskWebwidgetService.zE('webWidget', 'identify', {
            name: decoded["displayName"],
            email: decoded["email"]
          });
          this._ngxZendeskWebwidgetService.zE('webWidget', 'show');
          this.currentUserSubject$.next(user);


          userflow.init(environment.userFlowToken)
          userflow.identify(decoded["userid"], {
            name: decoded["displayName"],
            email: decoded["email"],
            // signed_up_at: '2019-06-14T16:25:49Z'
            //date.toUTCString()
          })


          return user;
        }
        else {
          return response;
        }
      },
        err => {
          console.error(err)
        }));
  }
  forgotPassword(email) {
    return this.http.post<any>(`${environment.userApiUrl}/api/auth/forgotpassword`, { "userName": email })
      .pipe(map(response => {

        return response;

      },
        err => {
          console.error(err)
        }));
  }
  confirmForgotPassword(email, code, password) {
    return this.http.post<any>(`${environment.userApiUrl}/api/auth/confirmforgotpassword`, { "userName": email, "confirmationCode": code, "password": password })
      .pipe(map(response => {
        return response;
      },
        err => {
          console.error(err)
        }));
  }
  refreshToken() {
    return this.http.post<any>(`${environment.userApiUrl}/api/auth/refreshtoken`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap(res => {
      this.storeJwtToken(res.idToken);

    },
      err => {
        console.error(err)
        this.logout();
      }));


  }

  logout() {
    this.currentUserSubject$.next(null);
    localStorage.clear();
    this._ngxZendeskWebwidgetService.zE('webWidget', 'hide');
    userflow.reset();
    this.router.navigate(["../login"]);
  }
  /*
    get isLoggedIn() {
      return (true);
    }
    set isLoggedIn(isLoggedIn: boolean) {

      localStorage.setItem("isLoggedIn", 'true');
      this._isLoggedIn = isLoggedIn;

    }
  */
  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }
  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }


  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeRefreshToken(token: string) {
    localStorage.setItem(this.REFRESH_TOKEN, token);
  }

  private logLastLogin() {
    localStorage.setItem(this.LAST_LOGIN, new Date().getTime().toString());

    //this.isSessionExpired();

  }

  getLastLogin() {
    localStorage.getItem(this.LAST_LOGIN);

  }
  isSessionExpired() {
    var lastLogin = localStorage.getItem(this.LAST_LOGIN);

    //var lastLogin = 1653153630 * 1000;
    var currentTime = new Date();
    console.log("session", (currentTime.getTime() - (new Date(Number(lastLogin))).getTime()) / (1000 * 3600));

    return (currentTime.getTime() - (new Date(Number(lastLogin))).getTime()) / (1000 * 3600) > 12
  }

  setItem(name: string, value: string){
    localStorage.setItem(name, value);
  }
  getItem(name: string){
    return localStorage.getItem(name);
  }

}
